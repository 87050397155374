
import { PsObject } from "@/object/core/PsObject";
import Product from "./Product";

export default class CategorywiseItem extends PsObject<CategorywiseItem> {

    id : string = '';
    catId : string = '';
    catName : string = '';
    catOrdering : string = '';
    status : string = '';
    addedDate : string = '';
    items : any = [];
  
    


    init(

        id : string,
        catId : string,
        catName : string,
        catOrdering : string,
        status : string,
        addedDate : string, 
        items: any,
       
        

    ) {
        this.id = id;
        this.catId = catId;
        this.catName = catName;
        this.catOrdering = catOrdering;
        this.status = status;
        this.addedDate = addedDate;
        this.items = items;

        return this;

    }

    getPrimaryKey(): string {
        return this.id;
    }

    toMap(object: CategorywiseItem): any {
        const map = {};

        map['id'] = object.id;
        map['cat_id'] = object.catId;
        map['cat_name'] = object.catName;
        map['cat_ordering'] = object.catOrdering;
        map['status'] = object.status;
        map['added_date'] = object.addedDate;
        map['items'] = object.items;
        // map['items'] = new Product().toMap(object.items);
      


        return map;
    }

    toMapList(objectList: CategorywiseItem[]): any[] {
        const mapList: any[] = [];
        for (let i = 0; i < objectList.length; i++) {
            if (objectList[i] != null) {
                mapList.push(this.toMap(objectList[i]));
            }
        }

        return mapList;
    }

    fromMap(obj: any) {
        return new CategorywiseItem().init(

            obj.id,
            obj.cat_id,
            obj.cat_name,
            obj.cat_ordering,
            obj.status,
            obj.added_date,
            obj.items,
            // new Product().fromMap(obj.items)
        );
    }

    fromMapList(objList: any[]): CategorywiseItem[] {
        const categorywiseItemList: CategorywiseItem[] = [];
        for (const obj in objList) {
            if (obj != null) {
                categorywiseItemList.push(this.fromMap(obj));
            }
        }

        return categorywiseItemList;
    }
}
