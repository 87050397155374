export default class PsConfig {

    static versionNo : String = '';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///


    static fixedDomain: String = "https://vahanwale.com/backend"; 
    static domain: String = "https://vahanwale.com/backend";

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    // static fixedDomain: String = "https://vahanwale.com/backend";
    // static domain: String = "http://localhost:3000";

    ///
    /// Firebase Config
    ///
    
    static firebaseConfig = {
        apiKey: "AIzaSyCSGsNJeK_g70vj87HBvteffY3bnAMt9tE",
        authDomain: "vahanwale-6edc6.firebaseapp.com",
        databaseURL: "https://vahanwale-6edc6-default-rtdb.firebaseio.com",
        projectId: "vahanwale-6edc6",
        storageBucket: "vahanwale-6edc6.appspot.com",
        messagingSenderId: "459510478500",
        appId: "1:459510478500:web:d65a14a28ff13b625bd831",
        measurementId: "G-48X8K6CBK8"
      };

    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
 
    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = true;

    /// Is Demo
    ///
    static is_demo : Boolean = false;

    static item_upload_ui1 : Boolean = false;

    static item_upload_ui2 : Boolean = true;
}
