export default class PhoneLoginParameterHolder {

    phoneId: string = '';
    userName: string = '';
    userPhone: string = '';
    deviceToken: string = '';
    platformName: string = '';
    mobileNumber: string = '';
    otp: string = '';

    PhoneLoginParameterHolder() {
        this.phoneId = '';
        this.userName = '';
        this.userPhone = '';
        this.deviceToken = '';
        this.platformName = '';
        this.mobileNumber = '';
        this.otp = '';

    }

    toMap(): {} {
        const map = {};
        map['phone_id'] = this.phoneId;
        map['user_name'] = this.userName;
        map['user_phone'] = this.userPhone;
        map['device_token'] = this.deviceToken;
        map['platform_name'] = this.platformName;
        map['mobile_number'] = this.mobileNumber;
        map['otp'] = this.otp;


        return map;
    }
}